import { createStore } from 'vuex';
import { Office } from '@/models/office';

export default createStore({
  state: {
    office: null,
    querySearch: {},
  },
  getters: {
    getOffice: (state) => state.office,
    getQuerySeach: (state) => state.querySearch,
  },
  mutations: {
    setOffice: (state, office) => (state.office = office),
    setQuerySearch: (state, querySearch) => (state.querySearch = querySearch),
  },
  actions: {
    fetchOffice: ({ getters, commit }, id) => {
      if (getters.getOffice?.id === id) return new Promise(() => getters.getOffice);

      return Office.set(id).then((office) => {
        commit('setOffice', office);
        return office;
      });
    },
  },
  modules: {},
});
