import { createRouter, createWebHistory } from 'vue-router';
const HomeView = () => import('../views/HomeView.vue');
const DetailsView = () => import('../views/DetailsView.vue');
const CartView = () => import('../views/CartView.vue');
const ErrorView = () => import('../views/ErrorView.vue');
const SuccessPayment = () => import('../views/PaymentSuccess.vue');

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: (route) => ({ ...route.params, ...route.query, office_id: route.query.office_id }),
  },
  {
    path: '/details/:id',
    name: 'details',
    component: DetailsView,
    props: (route) => ({ ...route.params, office_id: route.query.office_id }),
  },
  {
    path: '/mon-panier',
    name: 'Cart',
    component: CartView,
    props: (route) => ({ ...route.params, office_id: route.query.office_id }),
  },
  {
    path: '/error-office-id',
    name: 'Error',
    component: ErrorView,
  },
  {
    path: '/success-payment',
    name: 'SuccessPayment',
    component: SuccessPayment,
    props: (route) => ({ ...route.params, office_id: route.query.office_id }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Add a global navigation guard
router.beforeEach((to, from, next) => {
  // Check if office_id is present in the query string
  const hasOfficeId = to.query.office_id !== undefined;

  // Check if the route is not the error page to avoid infinite redirection
  if (to.name !== 'Error' && !hasOfficeId) {
    next({ name: 'Error' });
  } else {
    // Continue with the navigation
    next();
  }
});

export default router;
