import { BASE_URL, IMG_BASE_URL } from '@/env';

// Function to convert a duration into : days, hours, minutes, and seconds.
export function formatDuration(seconds) {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor(seconds / 3600) % 24;
  const minutes = Math.floor(seconds / 60) % 60;
  const remainingSeconds = seconds % 60;
  const formattedDuration = [];

  if (days > 0) formattedDuration.push(`${days === 1 ? '1 journée' : `${days} journées`}`);
  if (hours > 0) formattedDuration.push(`${hours} ${hours === 1 ? 'heure' : 'heures'}`);
  if (minutes > 0) formattedDuration.push(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`);
  if (remainingSeconds > 0)
    formattedDuration.push(`${remainingSeconds} ${remainingSeconds === 1 ? 'seconde' : 'secondes'}`);

  return formattedDuration.join(' ');
}

export function getImagePath(images) {
  const baseUrl = IMG_BASE_URL || '';
  const joinPaths = (base, file) => (base.endsWith('/') ? base.slice(0, -1) : base) + '/' + file;

  const fileName = images && Array.isArray(images) && images.length > 0 ? images[0].optimized : null;
  return fileName ? joinPaths(baseUrl, fileName) : null;
}

class BackendCallError extends Error {
  status = undefined;
  body = undefined;

  constructor(message, status, response) {
    super(message);
    this.status = status;
    this.body = response;
    this.name = 'BackendCallError';
  }
}

export async function callBackend(method, path, params = {}) {
  const queryParamsString = new URLSearchParams(params.queryParams).toString();
  const url = `${BASE_URL}/${path}?${queryParamsString}`;

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method.toUpperCase(),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: params.body ? JSON.stringify(params.body) : undefined,
    })
      .then(async (response) => {
        let body = undefined;
        try {
          body = await response.json();
        } catch (e) {
          body = undefined;
        }

        if (response.status < 400) {
          resolve(body);
        } else {
          reject(new BackendCallError('Unknown Backend Error', response.status, body));
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function getFirstAndLastDateOfMonth(year, month) {
  return {
    firstDayOfMonth: toLocaleFormat(new Date(year, month, 1)),
    lastDayOfMonth: toLocaleFormat(new Date(year, month + 1, 0)),
  };
}

export function toLocaleFormat(date) {
  const pad = (n) => (n < 10 ? '0' : '') + n;
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
}
