import { callBackend } from '@/utils/shared-functions';

export class Office {
  id = undefined;
  name = undefined;
  presentation = undefined;
  phone = undefined;
  cellphone = undefined;
  email = undefined;
  address = undefined;
  openingTime = undefined;
  website = undefined;
  siret = undefined;

  constructor(details) {
    this.id = details.id;
    this.name = details.name;
    this.presentation = details.presentation;
    this.phone = details.phone;
    this.cellphone = details.cellPhone;
    this.email = details.email;
    this.address = details.address;
    this.openingTime = details.openingTime;
    this.website = details.website;
    this.siret = details.siret;
  }

  static singleton = undefined;

  static async set(officeId) {
    if (!Office.singleton) {
      const details = await callBackend('get', `companies/${officeId}`);
      Office.singleton = new Office(details);
    }
    return Office.singleton;
  }

  static get() {
    return Office.singleton;
  }
}
